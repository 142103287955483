
body {
  margin: 0;
  height: 100vh; 
}
  .auth-container {
    background-color: #c7e3eb49; 
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-attachment: fixed; 
    z-index: -1; 
}
  .auth-box {
    transition: all 0.5s ease-in-out;
    background-color: #b6bcdd78;
    padding: 1%;
    border-radius: 3px;
    box-shadow: 0px 0px 20px #9ccaa099;
width: 25vw; 
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
   
  }
  
  .sign-in-mode .left-panel,
  .sign-up-mode .right-panel {
    background-color: #7f77ff;
    color: white;
    transition: all 0.5s ease-in-out;
  }
  
  .left-panel,
  .right-panel {
    width: 50%;
    padding: 40px;
    box-sizing: border-box;
  }
  
  h1 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    margin-bottom: 15px;
    padding: 12px;
    border: 1px solid #fff;
    border-radius: 5px;
    font-size: 16px;
    font-family: Poppins;
  }
  label {
    display: inline-block;
    font-family: 'Poppins';
    font-size: 14px;
}
  button {
    background-color: #7f77ff;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    font-family: Poppins;
  }
  
  button:hover {
    background-color: #6e66cc;
  }
  
  .social-icons a {
    margin-right: 10px;
    color: #7f77ff;
  }
  
  .social-icons a:hover {
    color: #6e66cc;
  }
  
  a {
    text-decoration: none;
    color: #7f77ff;
    margin-top: 10px;
    font-family: Poppins;
  }
  .loginText{
    font-family: Poppins;
    text-decoration: none;
    font-size: 10px;
  }
  a:hover {
    color: #6e66cc;
  }
  
  p {
    margin-bottom: 20px;
    font-size: 16px;
    font-family: Poppins;
  }
  
  .sign-in-mode .right-panel,
  .sign-up-mode .left-panel {
    opacity: 0;
    pointer-events: none;
    transform: translateX(-50%);
  }
  
  .sign-in-mode .left-panel {
    transform: translateX(0);
    pointer-events: all;
    opacity: 1;
  }
  
  .sign-up-mode .right-panel {
    transform: translateX(0);
    pointer-events: all;
    opacity: 1;
  }
  .alert-danger{
    font-family: Poppins;
    font-size: 12px;
    padding: 0;
    text-align: center;
  }