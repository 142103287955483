.header {
  position: fixed;
  width: calc(100% - 150px);
  height: 40px;
  top: 0;
  left: 150px;
  background-color: #eae7e7;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.66);
}

.nav-link {
  color: #333;
  font-size: 11px;
  text-decoration: none; 
  margin: 0;
  padding: 5px;
}

.nav-link:hover {
  background-color: transparent;
  border-radius: 4px;
  margin: 0;
  color: #878484; 
}

.header .container-fluid {
  display: flex;
  justify-content: flex-end;
}

.logout-link {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.pl-1{
  font-size: 12px;
  font-family: 'Poppins';
}