@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
 
 
 /* background:radial-gradient(circle, #bcbcca, #e4c5ff, #ebd1ef); */ 
 
 .dashboard-container {
  display: contents;
  height: fit-content;
  width: fit-content;
}
/* .global-container{
  margin:100px 0 0 260px;
  position: absolute;
  width: 100%;
  z-index: -1000;
  padding-right: 100px;
  padding-left: 100px;
} */
.h1,h1,h2{
  font-size: 14px;
  text-align: left;
  font-family: Poppins; 
}
p{
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-family: Poppins;
  color: #000000
}
.global-container {
  display: flex;
  margin-left: 160px;
  margin-top: 48px;
  position: absolute; 
  z-index: 1; 
  overflow-x: hidden; 
  width: calc(100% - 170px); 
  background-color: #b2b7b600;
  border-radius: 5px;
}

/* Targeting the scrollbar */
.custom-scrollbar {
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Ensure contents don't wrap to the next line */
}

/* Webkit-based browsers */
.custom-scrollbar::-webkit-scrollbar {
  height: 12px; /* Height of the horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
  border: 3px solid #f1f1f1; /* Creates padding around the thumb */
}

/* Hover effect */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
  scrollbar-width: thin; /* Make the scrollbar thinner */
}

/* For IE and Edge (Legacy) */
.custom-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.btn-primary{
    background-color:#c3c7df ;
    --bs-btn-border-color:#c3c7df; 
    font-size: 11px;
    font-family: Poppins;
    color: #000000;
} 
.btn-primary:hover{
  background-color:#c3c7df ;
  --bs-btn-border-color:#c3c7df; 
  border-color: #c3c7df;
  color: #000000;
}
.table td {
  background-color: #fff;
  font-size: 10px;
  font-family: Poppins;
}
.form-control{
  font-size: 11.50px;
  padding: 8px;
  margin: 0;
  font-family: Poppins;
}
.dropdownInTable{
  /* background-color: transparent; */
  /* border-color: transparent; */
  font-family: Poppins;
  font-size: 11.50px;
}
.datePicker{
  
}
.table th {
  background-color: #f4f4f4;
  font-size: 11px;
  font-family: Poppins;
}
.btn-secondary{
  font-size: 11px;
  font-family: Poppins;
}
.btn .btn-light .btn-sm{
  font-size: 11px;
  font-family: Poppins;
}

.bg-warning{
  font-size: 11px;
  font-family: Poppins;
}


/* task stylings */
.input-with-icon {
  position: relative;
}

.input-with-icon i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  pointer-events: none;
}

.input-with-icon input {
  padding-right: 30px; /* To ensure the text doesn't overlap with the icon */
}
.radio-group {
  display: flex;
  align-items: center;
}

.radio-group .form-check {
  margin-right: 1rem; /* Adjust as needed */
}
h4{
  color:#c3c7df ;;
  font-family: Poppins;
}
.dropdownText{
  font-size: 12px;
  margin-right: 15px;
  font-family: Poppins; 
  padding: 0;
  margin: 0;
}
.touchable-global {
  display: inline-block;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;  
  transition: opacity 0.3s ease;
  font-family: Poppins;
}

.touchable-global:hover {
  opacity: 0.7;
}
