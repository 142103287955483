/* .sidebar {
    width: 250px;
    background: radial-gradient(
      circle, 
      #7e7eaa, 
      #d2aaaa,  
      #ddd1bb   
    );
    color: #fff;
    height: 100%;
    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.66);
    overflow: hidden;
  } */
  .sidebar{
    width: 150px;
    background:#b6bcdd78;
    height: 100%;
    position: fixed;
    /* display: flex; */
    flex-direction: column;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.66);
    overflow: hidden;
    align-items: normal;
    justify-content: space-evenly;
    padding: 10px;
    z-index: 1000;
  }
  .sidebar-scroll {
    height: calc(100vh - 60px); /* Adjust this value based on the header height if any */
    overflow-y: auto; /* Enable vertical scrolling */
   /* padding-right: 10px; */
    /* Optional: add some padding to the right to avoid scrollbar overlap */
  }
  
  /* Optional: Customize scrollbar appearance */
.sidebar-scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers (Chrome, Safari) */
}
  .sidebar-scroll::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar thumb color */
    border-radius: 4px; /* Scrollbar thumb rounded corners */
  }
  
  .sidebar-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Scrollbar thumb color on hover */
  }
  

  .sidebar h2 {
    margin-top: 0;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 20px 0;
  }
  
  .sidebar ul li a {
    color: #6c24248c;
    text-decoration: none;
    font-size: 14px;
  }
  
  .sidebar ul li a:hover {
    text-decoration: underline;
    background-color: transparent;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #c3c7df !important;
}
.nav {
  display:contents !important;
}
.brand-text {
  color: black;
  text-decoration: none;
}
.brand-link{
  text-decoration: none;
}
.brand-image{
  display: flex;
  height: 7vh;
  width: 9vw;
  margin-bottom: 12px
}