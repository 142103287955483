.global-container {
    padding: 20px;
    background-color: #f8f9fa;
}

.card-custom {
    border: 1px solid #e3e6f0;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
    overflow: hidden;
}

.card-custom:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.card-body{
    margin: 1%;

}
.card-title {
    font-weight: bold;
    font-size:11px;
}

.card-text {
    font-size:12px;
    margin-bottom: 0.4rem;
}

.edit-button, .delete-button {
    padding: 6px;
    font-size:13px;
}

.edit-button:hover, .delete-button:hover {
    opacity: 0.85;
}

.text-primary {
    color: #007bff !important;
}

.text-muted {
    color: #6c757d !important;
}
