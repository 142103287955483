.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(10px);
}

.modal-container {
  background: rgb(128, 126, 126);
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 80%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.touchable {
  display: inline-block;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem !important;
  transition: opacity 0.3s ease;
}

.touchable:hover {
  opacity: 0.7;
}
.cancel{
  color: black;
  background-color: transparent;
}
.modal-body {
  margin: 20px 0;
}

.modal-footer {
  text-align: right;
}
