.pie {
    height: 50vh;
    padding: 10px;
}
.lead-summary-card {
    background-color: #f8f9fa;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .lead-summary-card h2 {
    color: #007bff;
    font-size: 36px;
  }
  
  .dashboard-chart-card {
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .task-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .task-list li {
    margin-bottom: 10px;
  }
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: #b377e3;
    transition: var(--bs-progress-bar-transition);
}