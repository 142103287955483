.dashboard-container {
    padding: 20px;
  }
  
  .dashboard-card {
    margin: 10px;
  }
  
  .overview-card {
    background-color: #f8f9fa;
    padding: 20px;
  }
  
  .metric-card {
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .metric-icon {
    color: #b6bcdd78;
  }
  
  .activity-list {
    list-style-type: none;
    padding: 0;
  }
  
  .activity-list li {
    padding: 10px 0;
    border-bottom: 1px solid #dee2e6;
  }
  
  .quick-actions {
    list-style-type: none;
    padding: 0;
  }
  
  .quick-actions li {
    margin-bottom: 10px;
  }
  
  .quick-actions .btn {
    width: 100%;
  }
  .readMore{
    color: #b6bcdd78  ;
    text-decoration: none;
  }
  .right-top {
    position: absolute;
    top: 0;
    right: 0;
  }
  
  .blinking-light {
    width: 10px; 
    height: 10px; 
    background-color: #f50000;
    display: inline-block;
    animation: blink 1s infinite;
    clip-path: polygon(
        50% 0%,   
        61% 35%,  
        98% 35%, 
        68% 57%,  
        79% 91%,  
        50% 70%, 
        21% 91%,
        32% 57%,  
        2% 35%,   
        39% 35%  
    );
    margin-left: 5px;
}
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }