  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .table th {
    background-color: #f4f4f4;
    font-size: 11px;
  }
  
  .table td {
    background-color: #fff;
    font-size: 10px;
  }
  .total-records{
  text-align: right;
  }

  